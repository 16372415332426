import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAGE_DEFAULT } from 'src/constants/app';

type State = {
  loading: boolean;
  entities: Response.InfoAccount[];
  currentPage: number;
};

const initialState: State = {
  loading: false,
  entities: [],
  currentPage: PAGE_DEFAULT,
};

const { actions, reducer } = createSlice({
  name: 'account_list',
  initialState,
  reducers: {
    updateEntities: (state, action: PayloadAction<Response.InfoAccount[]>) => {
      state.entities = action.payload;
    },
    uploadLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export { reducer };
export default actions;
