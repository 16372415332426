import React from 'react';
import { ConfigProvider } from 'antd';
import ja_JP from 'antd/es/locale/ja_JP';
import en_US from 'antd/es/locale/en_US';

import Notification from 'src/containers/Notification';
import Application from 'src/components/application';
import { useAppSelector } from 'src/store';
import 'src/styles/index.less';

function App() {
  const lang = useAppSelector((state) => state.ui.i18n.lang);
  return (
    <ConfigProvider
      locale={lang === 'ja' ? ja_JP : en_US}
      componentSize="middle"
    >
      <Application />
      <Notification />
    </ConfigProvider>
  );
}

export default App;
