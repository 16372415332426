import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  loadingImage: boolean;
  loadingAnimation: boolean;
  entityImage: Response.FileUpload | null;
  entityAnimation: Response.FileUpload | null;
};

const initialState: State = {
  loadingImage: false,
  loadingAnimation: false,
  entityImage: null,
  entityAnimation: null,
};

const { actions, reducer } = createSlice({
  name: 'metadata_upload_file',
  initialState,
  reducers: {
    updateEntityImage: (state, action: PayloadAction<Response.FileUpload>) => {
      state.entityImage = action.payload;
    },
    updateEntityAnimation: (
      state,
      action: PayloadAction<Response.FileUpload>,
    ) => {
      state.entityAnimation = action.payload;
    },
    updateLoadingImage: (state, action: PayloadAction<boolean>) => {
      state.loadingImage = action.payload;
    },
    updateLoadingAnimation: (state, action: PayloadAction<boolean>) => {
      state.loadingAnimation = action.payload;
    },
  },
});

export { reducer };
export default actions;
