import { combineReducers } from '@reduxjs/toolkit';

import user from './user';
import account from './account';
import auth from './auth';
import metadata from './metadata';

export default combineReducers({
  user,
  account,
  auth,
  metadata,
});
