import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  loadingAction: boolean;
  entities: Response.InfoMetadata[];
  start_at: string;
  end_at: string;
};

const initialState: State = {
  loading: false,
  loadingAction: false,
  entities: [],
  start_at: '',
  end_at: '',
};

const { actions, reducer } = createSlice({
  name: 'metadata_list',
  initialState,
  reducers: {
    updateEntities: (state, action: PayloadAction<Response.InfoMetadata[]>) => {
      state.entities = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateLoadingAction: (state, action: PayloadAction<boolean>) => {
      state.loadingAction = action.payload;
    },
    updateStartAt: (state, action) => {
      state.start_at = action.payload;
    },
    updateEndAt: (state, action) => {
      state.end_at = action.payload;
    },
  },
});

export { reducer };
export default actions;
