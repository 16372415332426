import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import LocalStorage from 'src/utils/LocalStorage';
import { I18n } from 'src/libs/i18n';
import { LANG } from 'src/constants/app';

type State = {
  lang: LANG;
  loading: boolean;
};

const initialState: State = {
  lang: LANG.EN,
  loading: false,
};

export const switchLang = createAsyncThunk<any, LANG>(
  'changeLang',
  async (lang) => {
    await I18n.changeLanguage(lang);
    return lang;
  },
);

const { actions, reducer } = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateLang: (state, action: PayloadAction<LANG>) => {
      LocalStorage.changeLang(action.payload);
      state.lang = action.payload;
    },
  },
});

export { reducer };
export default actions;
