import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  action: string | null;
  loading: boolean;
  entity: Response.UserEntity | null;
};

const initialState: State = {
  action: null,
  loading: false,
  entity: null,
};

const { actions, reducer } = createSlice({
  name: 'user_detail',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Response.UserEntity>) => {
      state.entity = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export { reducer };
export default actions;
