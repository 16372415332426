import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import 'antd/dist/antd.less';

import App from './App';
import { store } from './store';
import { I18n } from 'src/libs/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={I18n}>
      <App />
    </I18nextProvider>
  </Provider>,
);
