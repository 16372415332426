import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  action: string | null;
  loading: boolean;
  entity: Response.SettingTfaResponse | null;
};

const initialState: State = {
  action: null,
  loading: false,
  entity: null,
};

const { actions, reducer } = createSlice({
  name: 'tfa_info',
  initialState,
  reducers: {
    saveSettingTfaInfo: (
      state,
      action: PayloadAction<Response.SettingTfaResponse>,
    ) => {
      state.entity = action.payload;
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export { reducer };
export default actions;
