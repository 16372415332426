import { AxiosRequestConfig } from 'axios';

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const DATE_TIME_HOUR = 'YYYY-MM-DD HH:mm:ss';
export const ERROR_UI = 'wating_time';

export const config: AxiosRequestConfig = {
  baseURL: 'https://powerapi.onrender.com/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  AUTH_ME_INFO: 'info',
  LANG: 'admin_lang',
  THEME: 'admin_theme',
};

export const PER = {
  DEFAULT: 200,
  USER: 10,
};

export const PAGE_DEFAULT = 1;

export enum THEME {
  LIGHT = 'light',
}

export enum LANG {
  JA = 'ja',
  EN = 'en',
}

export enum SORT_ORDER {
  DESC = 'desc',
  ASC = 'asc',
}

export const APP_HEADER_HEIGHT = 64;
export const APP_FOOTER_HEIGHT = 60;

export const REGEX = {
  REGEX_URL:
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}\/?(\?(\w+(=[a-zA-Z0-9-]*)?(&\w+(=[a-zA-Z0-9-%]*)?)*)?$)?$/,
  REGEX_NOT_FULLSIZE: /^[a-zA-Z0-9.!#$%&@<>’*+/=?^_`',"[\]\s{|}\\(:;)~-]*$/gi,
  REGEX_VERIFY_CODE: /^[a-zA-Z0-9]+$/,
  REGEX_NOT_SPACE: /^[a-zA-Z0-9.!#$%&@<>’*+/=?^_`',"[\]{|}\\(:;)~-]*$/gi,
  REGEX_SPECIAL_STRING: /<[^>]*>|[^a-zA-Z0-9,.!#$%&:@;\-.!?<> ]/g,
  REGEX_IP_NUMBER:
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
  REGEX_EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const format = ['image', 'video', 'audio', '3D model'];

export const CODE_ID_METADATA_INVALID = 29;
