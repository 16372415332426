import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import history from 'src/libs/history';
import LoadingPage from 'src/components/application/LoadingPage';

const MainApp = React.lazy(() => import('src/components/application/MainApp'));

const Application: React.FC = () => {
  return (
    <Router history={history}>
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route component={MainApp} />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default Application;
