import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  entity: number | null;
};

const initialState: State = {
  entity: null,
};

const { actions, reducer } = createSlice({
  name: 'metadata_last',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<number>) => {
      state.entity = action.payload;
    },
  },
});

export { reducer };
export default actions;
